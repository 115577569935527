import React from "react"
import { navigate, Link } from "gatsby"
import _sortBy from "lodash/sortBy"
import "./index.css"

import {
  SEO,
  Layout,
  Button,
  DividingTitle,
  LoadingIndicatorPage,
} from "../components"
import {
  ScenarioHeroDisplay,
  ScenarioList,
  CategoryList,
} from "../features/Scenario/components"
import { useScenarioLists } from "../features/Scenario/hooks"
import { useAuthState } from "../features/Auth/state"
import { useScenarioState } from "../features/Scenario/state"
import { isClient } from "../lib/utils"

const IndexPage = () => {
  const { initialising, isAnonymous } = useAuthState()
  const {
    scenarioListState: { isFetchingList, scenarios },
    categoryListState: { categories },
  } = useScenarioState()
  const navToScenario = (scenarioId: string) => {
    navigate(`/scenario/${scenarioId}`)
  }
  const {
    primaryScenario,
    liveScenarios,
    resolvedScenarios,
  } = useScenarioLists(scenarios)

  return (
    <Layout>
      <SEO title="Home" />
      {isClient() &&
      (initialising ||
        isFetchingList ||
        !scenarios.length ||
        !categories.length) ? (
        <LoadingIndicatorPage />
      ) : (
        <>
          {!!primaryScenario && (
            <section className="LandingPage wrapper">
              <ScenarioHeroDisplay scenario={primaryScenario} />
              <div>
                <Button
                  primary
                  onClick={() => navToScenario(primaryScenario.id)}
                >
                  Make your prediction
                </Button>
              </div>
              {isAnonymous && (
                <div>
                  <Link to="/login">Subscribe to updates</Link>
                </div>
              )}
            </section>
          )}
          {liveScenarios.length > 0 && (
            <section className="MoreFromTipsta wrapper">
              <DividingTitle title="More questions from Tipsta" />
              <ScenarioList scenarios={liveScenarios} />
            </section>
          )}
          {resolvedScenarios.length > 0 && (
            <section className="RecentlyResolved wrapper">
              <DividingTitle title="Recently resolved" />
              <ScenarioList scenarios={resolvedScenarios} />
            </section>
          )}
          <section className="Categories">
            <h3>Browse categories</h3>
            <div className="wrapper-small">
              <CategoryList categories={categories} />
            </div>
          </section>
        </>
      )}
    </Layout>
  )
}

export default IndexPage
